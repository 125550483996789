



































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import QuoteItem from '@/components/quote/quote-item.vue'
import ProgressBar from '@/components/progress-bar/progress-bar.vue'

@Component({
  name: 'GhostQuote',
  components: { ProgressBar, QuoteItem }
})
export default class GhostQuote extends Vue {
  @Prop({ default: false }) withProgress !: boolean
}
