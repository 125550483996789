


































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Section } from '@/models/layout'
import QuestionComponent from '@/components/question-component/index.vue'
import QuestionGroupLabel from '@/components/question-component/question-group-label.vue'
import { SectionItem } from '@/models/questionnaire'
import { isGroupVisible } from '@/helpers/layout'
import { engine } from '@/engine'
import ApplicationMixin from '@/mixins/application-mixin'

@Component({
  name: 'GroupQuestionWrapper',
  components: {
    QuestionComponent, QuestionGroupLabel
  }
})
export default class GroupQuestionWrapper extends Mixins(ApplicationMixin) {
    @Prop() item!: Record<string, any>
    @Prop({ default: false }) isReviewing!: boolean

    /**
    * A group is visible if all its items are visible
    */
    isGroupVisible (layout: Section): boolean {
      return isGroupVisible(layout, this.questions, this.answers)
    }

    isQuestionVisible (item: SectionItem) {
      const question = this.questions.find(q => q.id === item)
      return question && question.condition ? engine.evaluate(question.condition, this.answers) : true
    }

    filteredGroupItems (groupItems: SectionItem[]) {
      return groupItems.filter(item => this.isQuestionVisible(item))
    }

    groupSum (group: Section) {
      let total = 0
      group.items.forEach((item: SectionItem) => {
        const answer = this.answers[item as string]
        if (answer) {
          total += answer as number
        }
      })

      return group.meta?.features?.sum.replace('{{ 0 }}', total.toLocaleString())
    }
}
