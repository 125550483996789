





























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'QuotesErrorsOverlay',
  setup (props, ctx) {
    const router = ctx.root.$router
    const refresh = () => {
      router.go(0)
    }
    return { refresh }
  }
})
