










































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  ctaState,
  InstallmentFrequency,
  PaymentOptions,
  Quote,
  QuoteCoverages,
  QuoteStatus
} from '@/models/quote'
import { Underwriter } from '@/models/underwriter'
import {
  cartModule,
  quoteModule,
  underwritersModule
} from '@/store/store-accessor'
import { Product, ProductType } from '@/models/product'
import {
  AnswerMap,
  LayoutSection,
  QuestionOption,
  SectionItem,
  SectionMetaLabel
} from '@/models/questionnaire'
import { InfoIcon } from 'vue-feather-icons'
import QuoteItem from '@/components/quote/quote-item.vue'
import HideCoveragesToggle from '@/components/quote/coverages/hide-coverages-toggle.vue'
import QuoteCta from '@/components/quote/quote-cta.vue'
import DownloadPdfCta from '@/components/quote/download-pdf-cta.vue'
import QuoteSelect from '@/components/quote/quote-select.vue'
import UnderwriterLogo from '@/components/quote/underwriter-logo.vue'
import CoverageDetail from '@/components/quote/coverages/coverage-detail.vue'
import { shallowEqual } from '@/helpers/utils'
import { isQuestionVisible } from '@/helpers/questions'
import isString from 'lodash/isString'

@Component({
  name: 'QuoteCard',
  components: {
    CoverageDetail,
    UnderwriterLogo,
    QuoteSelect,
    QuoteCta,
    DownloadPdfCta,
    HideCoveragesToggle,
    QuoteItem,
    InfoIcon
  }
})
export default class QuoteCard extends Vue {
  @Prop({ default: true }) showCta!: boolean;
  @Prop({ default: true }) showRates!: boolean;
  @Prop({ default: true }) showQuoteSelect!: boolean;
  @Prop({ default: true }) showCoverages!: boolean;
  @Prop({ default: true }) showLogo!: boolean;
  @Prop({ default: true }) showLearnMore!: boolean;
  @Prop({ default: false }) showMiniNotQuotedInfo!: boolean;
  @Prop({ required: true }) quote!: Quote;
  @Prop({ required: true }) coverageData: QuoteCoverages | undefined;

  coverageAnswers: AnswerMap = {};
  selectedPaymentOption = InstallmentFrequency.Yearly;
  displayCoverageSection: { [id: string]: boolean } = {
    [SectionMetaLabel.IncludedCoverages]: false,
    [SectionMetaLabel.OptionalCoverages]: false,
    [SectionMetaLabel.Deductibles]: false,
    [SectionMetaLabel.BuildingLimits]: false,
    [SectionMetaLabel.PropertyLimits]: false
  };

  quoteCTA: string = ctaState.ADD_TO_CART;
  dueOnPurchaseHint = [
    'This is the amount you’ll pay today at checkout for this policy.'
  ];

  quoteIdHint = [
    'This is the insurance company’s reference number for this quote.'
  ];

  // Watcher if quote is removed from cart, reset quoteCTA state
  @Watch('quoteInCart')
  quoteCartItemChanged (val: number) {
    if (!val) {
      this.quoteCTA = ctaState.ADD_TO_CART
    }
  }

  @Watch('quote', { deep: true })
  onQuoteChanged (newQuote: Quote) {
    const isEqual = shallowEqual(
      newQuote.coverageAnswers,
      this.coverageAnswers
    )
    if (isEqual) {
      this.quoteCTA = ctaState.ADD_TO_CART
    }
  }

  get quotedStatus () {
    return QuoteStatus.QUOTED
  }

  get referredStatus () {
    return QuoteStatus.REFERRED
  }

  get monthlyPayment (): number | undefined {
    /**
     * If monthly payment is already available on the policy, display that
     * If not available, then use policy premium and calculate monthly payment
     * This is done to avoid discrepancy in calculation vs true monthly payment
     */
    if (!this.quote.paymentOptions) return undefined
    return (
      this.quote.paymentOptions.find(
        (option) => option.installmentFrequency === InstallmentFrequency.Monthly
      )?.installmentPayment ?? parseFloat(this.quote.policyPremium) / 12
    )
  }

  get policyPremium (): number {
    return parseFloat(this.quote.policyPremium)
  }

  get quoteProduct (): Product | undefined {
    return quoteModule.productList.find(
      (product) => product.id === this.quote.productId
    )
  }

  get learnMoreUrl (): string | undefined {
    return this.quoteProduct?.learnMoreUrl
  }

  get quoteProductType (): ProductType | undefined {
    return quoteModule.productTypeList.find(
      (productType) => productType.id === this.quoteProduct?.id
    )
  }

  get underwriter (): Underwriter | undefined {
    const id = this.quoteProduct?.underwriterId
    if (!id) return undefined
    return underwritersModule.underwriterById(id)
  }

  get paymentPlans (): QuestionOption[] {
    if (!this.quote.paymentOptions) return []
    return this.quote.paymentOptions.map<QuestionOption>((currentPlan) => {
      const payment =
        currentPlan.installmentFrequency === InstallmentFrequency.Yearly
          ? currentPlan.downpayment.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })
          : currentPlan.installmentPayment.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })
      return {
        key: currentPlan.installmentFrequency,
        label: `${currentPlan.installmentFrequency} - ${payment}`
      }
    })
  }

  get selectedPaymentPlan (): PaymentOptions | undefined {
    return (
      this.quote.paymentOptions &&
      this.quote.paymentOptions.find(
        (option) => option.installmentFrequency === this.selectedPaymentOption
      )
    )
  }

  get dueOnPurchase (): number {
    return this.selectedPaymentPlan?.downpayment ?? 0
  }

  get dueOnPurchaseFrequency (): string | undefined {
    if (this.selectedPaymentOption === InstallmentFrequency.Yearly) {
      return '(1 year)'
    } else if (this.monthlyPayment) {
      const frequency = Math.round(this.dueOnPurchase / this.monthlyPayment)
      return `(${frequency} ${frequency === 1 ? 'month' : 'months'} )`
    }
  }

  get quoteInCart () {
    return cartModule.cartItems.find(
      (cartItem) => cartItem.quoteId === this.quote.id
    )
  }

  get inCartState () {
    return ctaState.IN_CART
  }

  get editState () {
    return this.quoteCTA === ctaState.EDIT_REMOVE_FROM_CART
  }

  get requiresUpdate () {
    return this.quoteCTA === ctaState.UPDATE_POLICY
  }

  isIncludedCoverageGroup (label: SectionMetaLabel) {
    return label === SectionMetaLabel.IncludedCoverages
  }

  mounted () {
    this.coverageAnswers = { ...this.quote.coverageAnswers }
    if (this.quoteInCart) {
      this.quoteCTA = ctaState.EDIT_REMOVE_FROM_CART
    }
  }

  isGroup (item: SectionItem): item is LayoutSection {
    return typeof item === 'object'
  }

  getQuestionData (questionId: string) {
    return this.coverageData?.questions.find(
      (question) => question.id === questionId
    )
  }

  isQuestion (val: SectionItem) {
    return isString(val)
  }

  showDownloadBtn (quote: Quote) {
    return quote.status === QuoteStatus.QUOTED
  }

  selectPaymentOption (selection: InstallmentFrequency) {
    if (
      this.quote.paymentOptions?.some(
        (option) => option.installmentFrequency === selection
      )
    ) {
      this.selectedPaymentOption = selection
    }
  }

  selectOccurrenceLimit (selection: string) {
    quoteModule.updateOccurrenceLimit({ selection, quoteId: this.quote.id })
  }

  selectDeductible (selection: string) {
    quoteModule.updateDeductible({ selection, quoteId: this.quote.id })
  }

  selectCoverage (data: { selection: string; coverageId: string }) {
    this.quoteCTA = ctaState.UPDATE_POLICY
    quoteModule.updateCoverage({
      selection: data.selection,
      quoteId: this.quote.id,
      coverageId: data.coverageId
    })
  }

  /**
   * Button will serve two purposes
   * - based on carrier ask, customer must see included coverages first before purchasing a policy
   * - If included coverages are not visible, Select Policy will toggle visiblity
   * - If included cverages are already visible, then policy will be added to cart
   */
  policyCta () {
    /*
     SELECT POLICY
     */
    if (this.quoteCTA === ctaState.SELECT) {
      this.displayCoverageSection[SectionMetaLabel.IncludedCoverages] = true
      this.quoteCTA = ctaState.ADD_TO_CART
    } else if (this.quoteCTA === ctaState.EDIT_REMOVE_FROM_CART) {
      /*
       REMOVE FROM CART
       */
      cartModule.removeItem(this.quote.id)
      quoteModule.removeQuoteSelection(this.quote.id)
      this.quoteCTA = ctaState.ADD_TO_CART
    } else if (this.quoteCTA === ctaState.UPDATE_POLICY) {
      /*
       UPDATE POLICY
       */
      this.$emit('update-quote', {
        ...this.quote
      })
    } else {
      /*
       ADD TO CART
       */
      quoteModule.settingQuoteSelections({
        quoteId: this.quote.id,
        selectedPaymentOption: this.selectedPaymentOption
      })
      cartModule.addItem({
        quoteId: this.quote.id,
        slug: this.quote.productSlug
      })
      this.quoteCTA = ctaState.IN_CART
      setTimeout(() => {
        this.quoteCTA = ctaState.EDIT_REMOVE_FROM_CART
      }, 2000)
    }
  }

  showHideSection (sectionLabel: SectionMetaLabel | undefined) {
    if (sectionLabel) {
      this.displayCoverageSection[sectionLabel] =
        !this.displayCoverageSection[sectionLabel]
    }
  }

  isGroupVisible (label: SectionMetaLabel) {
    return this.displayCoverageSection[label]
  }

  numberOfVisibleCoverages (questionData: SectionItem[]): number {
    return questionData.filter((item) => {
      const question = this.coverageData?.questions.find((q) => q.id === item)
      return question
        ? isQuestionVisible(question, this.quote.coverageAnswers)
        : false
    }).length
  }

  groupLabel (item: LayoutSection): string {
    let numItems: number | string = this.numberOfVisibleCoverages(item.items)

    // The limit of 5 included coverages is based on current UX directions
    if (numItems > 5) {
      numItems = '5+'
    }

    return item.meta.label === SectionMetaLabel.IncludedCoverages
      ? `${numItems} included`
      : `${numItems} available`
  }
}
