

































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { InfoIcon } from 'vue-feather-icons'
import {
  AnswerMap,
  Question,
  QuestionType,
  SectionMetaLabel
} from '@/models/questionnaire'
import QuoteSelect from '@/components/quote/quote-select.vue'
import { engine } from '@/engine'
import EventBus from '@/event-bus'
import { renderMarkdown } from '@/helpers/markdown'
import { Events } from '@/models/events'

@Component({
  name: 'CoverageDetail',
  components: {
    InfoIcon,
    QuoteSelect
  }
})
export default class CoverageDetail extends Vue {
  @Prop({ required: true }) question!: Question;
  @Prop({ required: true }) answers!: AnswerMap;
  @Prop({ default: '' }) sectionLabel!: SectionMetaLabel;
  @Prop({ default: false }) disabled!: boolean;

  checked = false;

  @Emit('update-coverage')
  updateCoverage (selection: string) {
    this.checked = true
    return {
      selection: selection,
      coverageId: this.question.id
    }
  }

  get currentAnswer () {
    return this.answers?.[this.question.id] ?? null
  }

  get isOptionalCoverage () {
    return this.sectionLabel === SectionMetaLabel.OptionalCoverages
  }

  get currentQuestionType (): string | null {
    switch (this.question.type) {
      case QuestionType.Select:
        return 'QuoteSelect'
      case QuestionType.Void:
        return 'Void'
      case QuestionType.Boolean:
        return ''
      default:
        return null
    }
  }

  get isVisible (): boolean {
    return this.question.condition
      ? engine.evaluate(this.question.condition, {
        ...this.answers
      })
      : true
  }

  get coverageName (): string {
    return (
      this.question.shortQuestion ??
      this.question.questionWithinGroup ??
      this.question.question
    )
  }

  coverageNameMarkDown () {
    return renderMarkdown(
      this.question.shortQuestion ??
        this.question.questionWithinGroup ??
        this.question.question,
      true,
      'link brand-color'
    )
  }

  get displayQuestion () {
    return this.isVisible && this.currentQuestionType !== null
  }

  get noOptions () {
    return this.question.options?.length === 1
  }

  mounted () {
    if (this.currentAnswer) {
      this.checked = true
    }
  }

  isVoidQuestionType (questionType: QuestionType) {
    return questionType === QuestionType.Void
  }

  async updateOptionalCoverage () {
    if (this.question.type === QuestionType.Select) {
      await this.handleSelectQuestionType()
    } else if (this.question.type === QuestionType.Boolean) {
      this.handleBooleanQuestionType()
    }
  }

  async handleSelectQuestionType () {
    // Get a ref to the select control for this coverage
    const selectControl = this.$refs[this.question.id] as QuoteSelect

    // Get coverage selected value from select control
    const coverageSelection = selectControl.$data.answer

    // if there is no value selected, checkbox will not be checked and it will trigger the select box to open
    if (!coverageSelection) {
      await this.$nextTick()
      this.checked = false
      selectControl.$data.open = true
    } else {
      // If there is a selection and checked value will be toggled off
      // it will set current answer to null, and emit a null value for the selection
      // that will update the quote answers
      //
      // If there is a selection and toggle checkbox, emit select value back to the quote answers
      if (!this.checked) {
        this.$emit('update-coverage', {
          selection: null,
          coverageId: this.question.id
        })
      } else {
        this.$emit('update-coverage', {
          selection: coverageSelection,
          coverageId: this.question.id
        })
      }
    }
  }

  handleBooleanQuestionType () {
    this.$emit('update-coverage', {
      selection: this.checked,
      coverageId: this.question.id
    })
  }

  toggleHint () {
    EventBus.$emit(Events.TOGGLE_HINT, this.question.ui.hint)
  }
}
