































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { binderModule, wcModule } from '@/store/store-accessor'
import Select from '@/components/controls/select/index.vue'
import TextArea from '@/components/controls/text-area/index.vue'
import Toggle from '@/components/controls/toggle/index.vue'
import ToggleCheckbox from '@/components/controls/toggle-checkbox/index.vue'
import MultiSelect from '@/components/controls/multi-select/index.vue'
import Tiles from '@/components/tiles/index.vue'
import ProductType from '@/components/product-type/index.vue'
import ClassOfBusiness from '@/components/class-of-business/index.vue'
import AddressComponent from '@/components/address/index.vue'
import WcClassComponent from '@/components/wc-class/index.vue'
import { ValidationMessage } from '@/models/validation-messages'
import {
  Address,
  AnswerMap,
  AnswerValue,
  Question,
  QuestionControlType,
  QuestionType
} from '@/models/questionnaire'
import { InfoIcon, XIcon } from 'vue-feather-icons'
import TextInput from '@/components/controls/text-input/index.vue'
import BrizaMixin from '@/mixins'
import QuestionLayout from '@/layouts/question-layout.vue'
import QuestionLabel from '@/components/question-component/question-label.vue'
import SingleLineControlWrapper from '@/components/controls/text-input/singleline-control-wrapper.vue'
import DateControl from '@/components/controls/text-input/date-control.vue'
import Numeric from '@/components/controls/numeric/index.vue'
import DrawerDisplayMixin from '@/mixins/drawer-display-mixin'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import Drawer from '@/components/drawer/index.vue'
import MultiSelectToggle from '@/components/multi-select-toggle/index.vue'
import Checkbox from '@/components/controls/checkbox/index.vue'
import ReviewEditQuestionToggle from '@/components/review/review-edit-question-toggle.vue'

@Component({
  name: 'ReviewQuestion',
  components: {
    ReviewEditQuestionToggle,
    Drawer,
    QuestionLabel,
    QuestionLayout,
    TextInput,
    TextArea,
    Toggle,
    ToggleCheckbox,
    MultiSelect,
    Tiles,
    ProductType,
    Select,
    ClassOfBusiness,
    WcClassComponent,
    AddressComponent,
    InfoIcon,
    SingleLineControlWrapper,
    DateControl,
    Numeric,
    XIcon,
    MultiSelectToggle,
    Checkbox
  }
})
export default class QuestionComponent extends Mixins(BrizaMixin, DrawerDisplayMixin, BrokerageMixin) {
  @Prop() readonly questions!: Question[]
  @Prop() readonly questionKey!: string
  @Prop() readonly answers!: AnswerMap
  @Prop() readonly group!: boolean
  @Prop() readonly questionIndex: number | undefined
  @Prop() readonly groupLength!: number
  @Prop() readonly groupItems!: string[]
  @Prop({ default: false }) isReviewing!: boolean

  hover = false
  answer: AnswerValue = ''
  multi = false
  showingEditToggle = false

  @Watch('isEditingQuestion')
  onEditingQuestionChanged (val: string | object) {
    if (val) {
      this.$nextTick()
    }
  }

  get isEditingQuestion (): string {
    return binderModule.toggleEditQuestion
  }

  get itemsRequiringUpdate () {
    return binderModule.validationMessages.map(msg => msg.field)
  }

  get isPartOfGroupRequiringUpdate () {
    return binderModule.validationMessages.find(error => error.field === this.currentQuestion.id)?.message || ''
  }

  get groupRequiresUpdate (): boolean {
    if (this.group) {
      return this.itemsRequiringUpdate.some(questionID => this.groupItems.some(x => x === questionID))
    } else {
      return false
    }
  }

  get isRequiringUpdate () {
    return this.error && !this.isCurrentQuestionBeingEdited
  }

  get lastGroupQuestion (): boolean {
    return this.questionIndex === this.groupLength - 1
  }

  get isCurrentQuestionBeingEdited () {
    return this.isEditingQuestion === this.currentQuestion.id
  }

  // filter-components the current question from the question array
  get currentQuestion (): Question {
    const question = this.questions.find(question => question.id === this.questionKey)
    if (!question) {
      throw new Error(`question ${this.questionKey} not found`)
    }
    return question
  }

  // location string interpolation for class of business questions
  get classOfBusinessQuestionLabel (): string {
    const locationIndex = this.questionKey.substring(0, 9)
    const locationAddressKey = Object.keys(this.answers).find(key => key === `${locationIndex}Address`)
    if (locationAddressKey) {
      const address: Address = this.answers[locationAddressKey] as Address
      return `${address?.street} class of business`
    } else {
      return `${locationIndex} class of business`
    }
  }

  // validation messages array that holds all issues with each question
  get validationMessages (): ValidationMessage[] {
    return binderModule.validationMessages
  }

  // find the current question type
  get currentQuestionType (): string {
    this.multi = false
    switch (this.currentQuestion.type) {
      case QuestionType.Address:
        return 'AddressComponent'
      case QuestionType.SingleLine:
      case QuestionType.DateYear:
        return 'SingleLineControlWrapper'
      case QuestionType.MultiLine:
        return 'TextArea'
      case QuestionType.Select:
        if (this.currentQuestion.ui?.control === QuestionControlType.Toggle) {
          return 'Toggle'
        }
        return 'Select'
      case QuestionType.MultiSelect:
        this.multi = true
        if (this.currentQuestion.ui?.control === QuestionControlType.Toggle) {
          return 'MultiSelectToggle'
        }
        return 'Tiles'
      case QuestionType.Number:
        return 'Numeric'
      case QuestionType.Decimal:
        return 'Numeric'
      case QuestionType.Currency:
        return 'Numeric'
      case QuestionType.Date:
        return 'DateControl'
      case QuestionType.Boolean:
        return this.currentQuestion.ui?.control === QuestionControlType.Checkbox ? 'Checkbox' : 'ToggleCheckbox'
      case QuestionType.ClassOfBusiness:
        return 'ClassOfBusiness'
      case QuestionType.WcClassCode:
        return 'WcClassComponent'
      case QuestionType.ProductType:
        return 'ProductType'
      case QuestionType.Void:
        return ''
      default:
        throw new Error(`component for question ${this.currentQuestion.id} does not exist`)
    }
  }

  // Getting answer to current question from answers prop
  get currentAnswer () {
    const answer = binderModule.answers[this.questionKey]

    // Unanswered questions have value "null" (relevant to conditional visibility rules)
    if (answer === undefined) {
      return null
    }

    return answer
  }

  get activeControl (): string {
    return binderModule.activeControl
  }

  get error (): string {
    return binderModule.validationMessages.find(error => error.field === this.currentQuestion.id)?.message || ''
  }

  get numberOfLocations (): number | null {
    return binderModule.answers.NumberOfLocations ? binderModule.answers.NumberOfLocations as number : null
  }

  updateAnswer (answer: AnswerValue) {
    this.isValid(answer, this.currentQuestion)
    if (this.currentQuestion.type === QuestionType.DateYear && typeof answer === 'string') {
      this.sendAnswer(answer.split('-', 1)[0], this.currentQuestion)
    } else {
      this.sendAnswer(answer, this.currentQuestion)
    }
  }

  toggleEditQuestion () {
    binderModule.setEditQuestion(this.currentQuestion.id)
  }

  async mounted () {
    const isNotOptional = this.currentQuestion.required ||
    [QuestionType.Boolean, QuestionType.Void].includes(this.currentQuestion.type) ||
      (this.currentQuestion.type === QuestionType.Select && this.currentQuestion.ui?.control === 'toggle' &&
        this.currentQuestion.default !== undefined)
    if (this.isReviewing && isNotOptional) {
      this.updateAnswer(this.currentAnswer)
    }
  }
}
