


























import { quoteModule } from '@/store/store-accessor'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { downloadFile } from '@/helpers/utils'
import LoadingIcon from '@/components/loading-icon/index.vue'

export default defineComponent({
  name: 'DownloadPdfCta',
  components: {
    LoadingIcon
  },
  props: {
    quoteUuid: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props) {
    const isLoading = ref(false)
    const hasProposal = ref(true)

    const fetchQuoteProposal = async () => {
      if (!hasProposal) return

      if (!props.quoteUuid) {
        throw new Error('Unable to process download - Parameters')
      }

      try {
        isLoading.value = true
        const [fileData, headers] = await quoteModule.fetchQuoteProposal({
          quoteId: props.quoteUuid
        })
        await downloadFile(fileData, headers)
        isLoading.value = false
      } catch (e: any) {
        setTimeout(() => {
          isLoading.value = false
        }, 500)
        hasProposal.value = false
      }
    }

    const buttonCTA = computed(() => {
      if (isLoading.value) return 'Downloading'
      if (!hasProposal.value) return 'PDF not available'
      return 'Download PDF'
    })

    return { fetchQuoteProposal, hasProposal, isLoading, buttonCTA }
  }
})
