


























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { cartModule, quoteModule, binderModule } from '@/store/store-accessor'
import { CartItem } from '@/models/cart'
import CartItemComponent from '@/components/quote/policy-cart/cart-item.vue'
import QuoteCard from '@/components/quote/quote.vue'
import { Quote, QuoteStatus, InstallmentFrequency } from '@/models/quote'
import { ProductList } from '@/models/product'
import GhostQuote from '@/components/quote/ghost-states/ghost-quote.vue'
import { ROUTES } from '@/router/routes'

@Component({
  name: 'PolicyCart',
  components: { QuoteCard, CartItemComponent, GhostQuote }
})

export default class PolicyCart extends Vue {
  @Prop({ required: true }) cartItems!: CartItem[]
  @Prop({ default: false }) isCheckout!: boolean

  get isQueued () {
    return this.quote?.status === QuoteStatus?.QUEUED
  }

  get isQuoted () {
    return this.quote?.status === QuoteStatus?.QUOTED
  }

  get quotes (): Quote[] {
    return quoteModule.quotesData
  }

  get pendingUpdateQuote (): string | null {
    return cartModule.pendingUpdateQuote
  }

  // Total down payments on all cart items
  get policyTotals (): number {
    return this.cartItems.reduce((total, cartItem) => {
      const policyInfoDownPayment =
        quoteModule.quoteSelections.find(policy => policy.quoteId === cartItem.quoteId)?.downpayment || 0
      return total + policyInfoDownPayment
    }, 0)
  }

  /**
   * Totals collected by agent after checkout
   */
  get collectedByAgent (): number {
    return cartModule.collectedByAgent
  }

  get checkoutTotal (): number {
    return this.policyTotals - this.collectedByAgent
  }

  get quote () {
    return this.quotes.find(quote => quote.id === this.quoteId)
  }

  get quoteId () {
    return this.pendingUpdateQuote || ''
  }

  getCoverageData (quoteId: string | null) {
    return quoteModule.quoteCoverages.find(data => data.quoteId === quoteId)
  }

  selectQuote () {
    if (this.isCheckout) {
      return this.$router.push({
        name: ROUTES.QUOTES,
        params: { id: binderModule.application?.id as string }
      })
    } else {
      this.$emit('toggle-cart')
    }
  }

  get quoteProductList (): ProductList[] {
    return quoteModule.quoteProductList
  }

  @Watch('isQuoted')
  onQuoted (val: true) {
    if (val) {
      this.$emit('quoted')
      this.addToCart()
    }
  }

  addToCart () {
    // re-adding new quote data to the cart
    cartModule.removeItem(this.quoteId)
    quoteModule.removeQuoteSelection(this.quoteId)
    this.$nextTick(() => {
      quoteModule.settingQuoteSelections({ quoteId: this.quoteId, selectedPaymentOption: InstallmentFrequency.Yearly })
      cartModule.addItem({ quoteId: this.quoteId, slug: this.quote?.productSlug as string })
    })
  }

  showQuote (quoteId: string | null) {
    cartModule.setPendingUpdateQuote(quoteId)
  }
}
