







import { defineComponent, ref, watchEffect } from '@vue/composition-api'
import useIntersection from '@/use/useIntersection'

export default defineComponent({
  name: 'ScrollVisibility',
  setup () {
    const options = {
      root: null,
      rootMargin: '0px 0px 0px',
      threshold: 1
    }
    const elRef = ref(null)
    const elClass = ref('')
    const { observedEntry } = useIntersection(elRef, options)

    watchEffect(() => {
      if (!observedEntry.value) return
      const isVisible = observedEntry.value.intersectionRatio === 1
      elClass.value = isVisible ? 'is-visible' : ''
    })
    return { elRef, elClass }
  }
})
