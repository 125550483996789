

























import { defineComponent } from '@vue/composition-api'
import useBrokerage from '@/use/useBrokerage'
import IconBase from '@/components/icon-library/icon-base.vue'
import AgentSupport from '@/components/icon-library/agent-support.vue'
import CalloutBox from '@/components/callout-box/index.vue'

export default defineComponent({
  name: 'AgentAlert',
  components: { AgentSupport, IconBase, CalloutBox },
  setup () {
    const { brokerageName, brokerageEmail, brokeragePhone } = useBrokerage()
    return { brokerageName, brokerageEmail, brokeragePhone }
  }
})
