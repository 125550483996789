










import { Component, Prop, Mixins } from 'vue-property-decorator'
import QuestionComponent from '@/components/question-component/index.vue'
import ApplicationMixin from '@/mixins/application-mixin'

@Component({
  name: 'SingleQuestionWrapper',
  components: {
    QuestionComponent
  }
})
export default class SingleQuestionWrapper extends Mixins(ApplicationMixin) {
    @Prop() item!: string
    @Prop({ default: false }) isReviewing!: boolean
}
