













import { defineComponent, watchEffect, ref } from '@vue/composition-api'
import useIntersection from '@/use/useIntersection'
import EventBus from '@/event-bus'

export default defineComponent({
  name: 'ReviewSectionTitle',
  props: {
    depth: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    sectionid: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props) {
    const options = {
      root: null,
      rootMargin: '0px 0px 0px',
      threshold: 0.8
    }

    const elRef = ref(null)
    const elClass = ref('')
    const { observedEntry } = useIntersection(elRef, options)

    const setHighlightSection = () => {
      setTimeout(() => {
        EventBus.$emit('review-highlight-section', props.sectionid)
      }, 500)
    }

    watchEffect(() => {
      if (!observedEntry.value) return
      if (props.depth === 1) {
        const isVisible = observedEntry.value.intersectionRatio === 1
        elClass.value = isVisible ? 'is-visible' : ''
        setHighlightSection()
      }
    })

    return {
      elRef,
      elClass
    }
  }

})
