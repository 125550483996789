









































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StepCounter from '@/components/step-counter/index.vue'
import { binderModule, brokerageModule, cobModule } from '@/store/store-accessor'
import { validateForm } from '@/helpers/validations'
import { Question } from '@/models/questionnaire'
import { XIcon } from 'vue-feather-icons'
import TheSideNavigation from '@/components/the-side-navigation/index.vue'
import ApplicationMixin from '@/mixins/application-mixin'
import ApplicationLayout from '@/layouts/application-layout.vue'
import Spacer from '@/components/spacer/index.vue'
import LoadableMixin from '@/mixins/loadable-mixin'
import { Section } from '@/models/layout'
import { ROUTES } from '@/router/routes'
import Drawer from '@/components/drawer/index.vue'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import DrawerEditQuestion from '@/components/review/drawer/edit-question-drawer.vue'
import IconBase from '@/components/icon-library/icon-base.vue'
import Save from '@/components/icon-library/save.vue'
import ReviewHorakiEngine from '@/components/review/review-horaki-engine.vue'
import DrawerSaveForLater from '@/components/save-for-later/drawer/index.vue'
import RequireUpdateAlert from '@/components/review/require-update-alert.vue'
import ScrollVisibility from '@/components/scroll-visibility/scroll-visibility.vue'
import ReviewActions from '@/components/review/review-actions.vue'
import AgentAlert from '@/components/quote/agent-alert.vue'
import BackToTopButton from '@/components/back-to-top-button/index.vue'
import DrawerBusinessInfo from '@/components/business-info/drawer/index.vue'
import VueScrollTo from 'vue-scrollto'
import EventBus from '@/event-bus'
import { generateCompanyKey } from '@/helpers/utils'

@Component({
  name: 'Application',
  components: {
    DrawerEditQuestion,
    IconBase,
    DrawerBusinessInfo,
    ApplicationLayout,
    TheSideNavigation,
    StepCounter,
    Spacer,
    Drawer,
    Save,
    XIcon,
    ReviewHorakiEngine,
    AgentAlert,
    RequireUpdateAlert,
    ScrollVisibility,
    DrawerSaveForLater,
    BackToTopButton,
    ReviewActions
  }
})
export default class Application extends Mixins(ApplicationMixin, LoadableMixin, BrokerageMixin) {
  @Prop({ default: false }) isOverlay!: boolean;
  oldAnswers = ''
  // Question list for the application
  get questions (): Question[] {
    if (!binderModule.application) {
      return [] // Still loading
    }
    return binderModule.application.questionnaire.questions
  }

  get layout (): Section[] {
    if (!binderModule.application) {
      return [] // Still loading
    }
    return binderModule.application.questionnaire.layout as Section[]
  }

  get showSaveForLater (): boolean {
    return binderModule.toggleSaveForLater
  }

  get isEditingQuestion (): string {
    return binderModule.toggleEditQuestion
  }

  get BusinessName (): string | null {
    return this.answers.BusinessName as string || null
  }

  toggleSaveForLater (): void {
    binderModule.setSaveForLater()
  }

  toggleReviewOverlay () {
    binderModule.setDrawerState(false)
    binderModule.setReviewOverlay()
  }

  async created () {
    window.scrollTo(0, 0)
    this.showLoading()

    this.oldAnswers = JSON.stringify(binderModule.answers)

    try {
      await Promise.all([
        binderModule.checkApplication(),
        cobModule.initializeCOB()
      ])
    } catch (err: any) {
      this.$logger.warn(`Unsuccesful load of application: ${err.message}`)
    }

    if (!binderModule.application) {
      return this.$router.push({
        name: ROUTES.PAGE_NOT_FOUND
      })
    }

    await binderModule.setQuestionnaire(binderModule.application.questionnaire)

    document.title = brokerageModule.brokerage
      ? `${brokerageModule.brokerage.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title

    this.hideLoading()

    EventBus.$on('update-validation', () => {
      setTimeout(() => {
        this.validateReview()
      }, 500)
    })
    this.updateLocalStorageVariables()
  }

  updateLocalStorageVariables () {
    const key = generateCompanyKey(this.answers)
    sessionStorage.removeItem(`${key}_dialog`)
    localStorage.setItem(`${key}_route`, ROUTES.REVIEW)
  }

  jumpToFirstRequiredQuestion () {
    const el = document.querySelector(`[data-qid=${binderModule.validationMessages[0].field}]`)
    const container = this.isOverlay ? document.querySelector('.review-component') : document.querySelector('body')
    if (el) {
      VueScrollTo.scrollTo(el, 200, {
        container: (container as HTMLElement),
        offset: -230
      })
    }
  }

  answersChanged () {
    const currentAnswers = JSON.stringify(binderModule.answers)
    const changed = currentAnswers[[...this.oldAnswers].findIndex((el, index) => el !== currentAnswers[index])]
    return !!changed
  }

  // Continue button click to move to next section in the pre-app
  async moveNext () {
    this.showBtnLoading()

    if (!this.answersChanged()) {
      if (!this.isOverlay) {
        this.loadQuotes()
        return
      }
      this.toggleReviewOverlay()
      this.hideBtnLoading()
      return
    }

    this.validateReview()

    if (binderModule.validationMessages.length > 0) {
      this.jumpToFirstRequiredQuestion()
      this.hideBtnLoading()
      return
    }
    // only fire api request if validation passes and answers changed
    await binderModule.saveAnswers()

    this.loadQuotes()
  }

  loadQuotes () {
    if (this.$router?.currentRoute.name === ROUTES.QUOTES) {
      this.$emit('answers-changed')
    } else {
      return this.$router.push({
        name: ROUTES.QUOTES,
        params: { id: binderModule.application?.id as string }
      })
    }
  }

  validateReview () {
    const form = this.$refs.application as HTMLFormElement
    if (form && form.elements.length) {
      const validationMessages = validateForm(
        form,
        this.questions,
        this.answers
      )
      binderModule.updateFormValidation(validationMessages)
    }
  }
}
