
































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Question, QuestionOption } from '@/models/questionnaire'
import BrizaMixin from '@/mixins'
import { ChevronDownIcon, ChevronUpIcon, XIcon } from 'vue-feather-icons'
import SelectMixin from '@/mixins/select-mixin'

@Component({
  name: 'QuoteSelect',
  components: {
    ChevronDownIcon, ChevronUpIcon, XIcon
  }
})
export default class QuoteSelect extends Mixins(BrizaMixin, SelectMixin) {
  @Prop({ default: '' }) label!: string
  @Prop() question!: Question
  @Prop({ required: true }) value!: string | number | null
  @Prop({ required: true }) options!: QuestionOption[]
  @Prop({ required: true }) controlId!: string
  @Prop({ default: false }) disabled !: boolean
  get stateIcon () {
    return this.open
      ? 'chevron-up-icon'
      : 'chevron-down-icon'
  }

  @Watch('open')
  openStateChanged (val: boolean) {
    if (val) {
      document.addEventListener('click', this.handleClickOutside)
    } else {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }

  mounted () {
    if (this.value !== null) {
      this.selection = this.options.filter(option => option.key === this.value)[0]?.label ?? ''
    } else if (this.question?.default !== undefined) {
      this.selection = this.options.filter(option => option.key === this.question.default)[0]?.label ?? ''
    }
  }
}
