

















import { Component, Vue } from 'vue-property-decorator'
import { ChevronUpIcon } from 'vue-feather-icons'

@Component({
  name: 'HideCoveragesToggle',
  components: {
    ChevronUpIcon
  }
})
export default class HideCoveragesToggle extends Vue {

}
