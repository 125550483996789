






































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { InstallmentFrequency, Quote, QuoteSelection } from '@/models/quote'
import { binderModule, cartModule, quoteModule, underwritersModule, brokerageModule } from '@/store/store-accessor'
import { Product } from '@/models/product'
import { Underwriter, BillingType } from '@/models/underwriter'
import { getProductName } from '@/helpers/products'
import { InfoIcon } from 'vue-feather-icons'
import UnderwriterLogo from '@/components/quote/underwriter-logo.vue'
import { findQuoteById } from '@/helpers/quote'
import EventBus from '@/event-bus'
import { Events } from '@/models/events'

@Component({
  name: 'CartItemComponent',
  components: {
    UnderwriterLogo,
    InfoIcon
  }
})
export default class CartItemComponent extends Vue {
  @Prop({ required: true }) quoteId!: string
  @Prop({ required: true }) index!: number
  @Prop({ default: false }) isCheckout!: boolean

  hintCopy = {
    INSTALLMENT: 'This is the recurring amount you’ll pay when the policy is bound.',
    DUE_ON_PURCHASE: 'This is the amount you’ll pay today at checkout for this policy.',
    PAYMENT_PLAN: 'These are the interval payment options available from the insurance company'
  }

  get quoteInfo (): Quote | undefined {
    return findQuoteById(quoteModule.quotesData, this.quoteId)
  }

  get quoteSelection (): QuoteSelection | undefined {
    return quoteModule.quoteSelections.find(selection => selection.quoteId === this.quoteId)
  }

  get productInfo (): Product | undefined {
    return quoteModule.productList.find(product => product.id === this.quoteInfo?.productId)
  }

  get products (): Product[] {
    return quoteModule.productList
  }

  get underwriter (): Underwriter | undefined {
    return underwritersModule.underwriters.find(underwriter => underwriter.id === this.productInfo?.underwriterId)
  }

  get productName () {
    return getProductName(this.quoteSelection?.product)
  }

  get underwriterName () {
    return this.quoteId
  }

  get dueOnPurchaseFrequency (): string {
    if (this.quoteSelection) {
      if (this.quoteSelection?.installmentFrequency === InstallmentFrequency.Yearly) {
        return '(1 year upfront)'
      } else {
        const frequency = Math.round(this.quoteSelection?.downpayment / this.quoteSelection?.installmentPayment)
        return `(${frequency} months upfront)`
      }
    }
    return ''
  }

  get isDirectBilling () {
    return this.quoteInfo?.billingType === BillingType.Direct
  }

  get isAgencyBilling () {
    return this.quoteInfo?.billingType === BillingType.Brokerage
  }

  get brokerageName () {
    return brokerageModule.brokerageName
  }

  get collectPayment () {
    return this.quoteInfo?.collectPayment
  }

  removeItem () {
    cartModule.removeItem(this.quoteId)
    quoteModule.removeQuoteSelection(this.quoteId)
  }

  toggleHint (hint: string) {
    EventBus.$emit(Events.TOGGLE_HINT, [hint])
  }

  editPolicy () {
    if (this.isCheckout) binderModule.setShowCart()
    this.$emit('edit-policy', this.quoteId)
  }
}
