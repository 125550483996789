import { render, staticRenderFns } from "./agent-alert.vue?vue&type=template&id=23044b7a&"
import script from "./agent-alert.vue?vue&type=script&lang=ts&"
export * from "./agent-alert.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports