



















import { defineComponent } from '@vue/composition-api'
import { ChevronUpIcon } from 'vue-feather-icons'
import useScroll from '@/use/useScroll'
import VueScrollTo from 'vue-scrollto'

export default defineComponent({
  name: 'FeedbackLauncher',
  components: { ChevronUpIcon },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    isOverlay: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { scrollPosition } = useScroll()

    const goToTop = () => {
      const container = props.isOverlay ? document.querySelector('.review-component') : document.querySelector('body')
      VueScrollTo.scrollTo(container as HTMLElement, 200, {
        container: (container as HTMLElement),
        offset: -230
      })
    }
    return {
      goToTop,
      scrollPosition
    }
  }
})
