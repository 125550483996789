



































































































import {
  defineComponent,
  computed,
  nextTick,
  ref,
  onMounted,
  watch
} from '@vue/composition-api'
import Drawer from '@/components/drawer/index.vue'
import { XIcon } from 'vue-feather-icons'
import { binderModule } from '@/store/store-accessor'
import LoadingIcon from '@/components/loading-icon/index.vue'
import GroupQuestionWrapper from '@/components/question-component/group-question-wrapper.vue'
import SingleQuestionWrapper from '@/components/question-component/single-question-wrapper.vue'
import useScreen from '@/use/useScreen'
import useLoadable from '@/use/useLoadable'
import { ValidationMessage } from '@/models/validation-messages'
import { AnswerMap } from '@/models/questionnaire'
import EventBus from '@/event-bus'
import { Events } from '@/models/events'

export default defineComponent({
  name: 'DrawerEditQuestion',
  components: {
    Drawer,
    XIcon,
    LoadingIcon,
    GroupQuestionWrapper,
    SingleQuestionWrapper
  },
  props: {
    isOpen: { type: Boolean, default: false },
    showConfirmationMenu: { type: Boolean, default: true }
  },
  emits: ['close-drawer'],
  setup (props, { emit, root }) {
    const { isSmallScreen } = useScreen()
    const { hideBtnLoading, showBtnLoading, btnLoading } = useLoadable()
    const showingMobileMenu = ref(true)
    const allowSaveAndClose = ref(false)
    let oldAnswers: AnswerMap
    let oldValidationData: ValidationMessage[]

    const isEditingQuestion = computed(() => {
      return binderModule.toggleEditQuestion
    }) as any

    const answersChanged = () => {
      const str1 = JSON.stringify(oldAnswers)
      const str2 = JSON.stringify(binderModule.answers)
      if (!str1) return false
      const changed = str2[[...str1].findIndex((el, index) => el !== str2[index])]
      return !!changed
    }

    const isNotEmpty = () => {
      const inputEl: HTMLInputElement | null = document.querySelector('.editquestion-content input')
      if (inputEl?.value.length === 0) {
        return false
      }
      return true
    }

    const handleClose = async () => {
      // undo data
      binderModule.undoAnswers({ answers: oldAnswers, validationMessages: oldValidationData })
      binderModule.setEditQuestion('')
    }

    const saveAndClose = async () => {
      if (!isNotEmpty() || !answersChanged()) return
      showBtnLoading()
      binderModule.saveAnswers()

      if (answersChanged()) {
        EventBus.$emit('update-validation')
        setTimeout(() => {
          hideBtnLoading()
          emit('close-drawer')
          binderModule.setEditQuestion('')
          root.$toast.success('Successfully updated')
        }, 500)
      }
    }

    const setAllowSaveAndClose = () => {
      const allows = isNotEmpty() && answersChanged()
      allowSaveAndClose.value = allows
    }

    const editHandler = () => {
      setAllowSaveAndClose()
    }

    const focusSaveBtn = async () => {
      const questionInput = document.activeElement as HTMLElement
      questionInput.blur()
      await nextTick()
      const confirmBtn = document.querySelector('[data-test="save-and-close"]') as HTMLElement
      confirmBtn.focus()
      editHandler()
    }
    watch(() => props.isOpen, (val) => {
      if (val) {
        if (props.showConfirmationMenu && isSmallScreen.value) { showingMobileMenu.value = false }
        oldAnswers = { ...binderModule.answers }
        oldValidationData = [...binderModule.validationMessages]
        hideBtnLoading()
      }
    })

    onMounted(() => {
      EventBus.$on(Events.UPDATE_ANSWER, () => {
        editHandler()
      })
    })

    return {
      saveAndClose,
      btnLoading,
      isEditingQuestion,
      isSmallScreen,
      showingMobileMenu,
      editHandler,
      allowSaveAndClose,
      handleClose,
      focusSaveBtn
    }
  }

})
