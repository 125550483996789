



































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import ReviewQuestion from '@/components/review/review-question.vue'
import { AnswerMap, Question, SectionItem } from '@/models/questionnaire'
import { Section } from '@/models/layout'
import { renderMarkdown } from '@/helpers/markdown'
import { isGroupVisible } from '@/helpers/layout'
import { engine } from '@/engine'
import QuestionGroupLabel from '@/components/question-component/question-group-label.vue'
import ReviewSectionTitle from '@/components/review/review-section-title.vue'
import { binderModule } from '@/store/store-accessor'

export default defineComponent({
  name: 'ReviewHorakiEngine',
  components: {
    ReviewQuestion, QuestionGroupLabel, ReviewSectionTitle
  },
  props: {
    answers: {
      type: Object as () => AnswerMap,
      required: true
    },
    layout: {
      type: Array as () => Section[],
      required: true
    },
    questions: {
      type: Array as () => Question[],
      required: true
    },
    visibleSections: {
      type: Array as () => string[],
      required: true
    },
    depth: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const isHoveredIndex = ref('')
    const markDown = (input: string) => {
      return renderMarkdown(input)
    }

    const groupRequiresUpdate = (item: any) => {
      const requiringUpdate = binderModule.validationMessages.map(msg => msg.field)
      return requiringUpdate.some(questionID => item.items.some((x: string) => x === questionID))
    }

    const isThisGroupVisible = (layout: Section) => {
      return isGroupVisible(layout, props.questions, props.answers)
    }

    const groupSum = (group: Section) => {
      let total = 0
      group.items.forEach((item: SectionItem) => {
        const answer = props.answers[item as string]
        if (answer) {
          total += answer as number
        }
      })
      return group.meta?.features?.sum.replace('{{0}}', total.toLocaleString())
    }

    const isQuestionVisible = (item: SectionItem) => {
      const question = props.questions.find(q => q.id === item)
      return question && question.condition ? engine.evaluate(question.condition, props.answers) : true
    }
    const filteredGroupItems = (groupItems: SectionItem[]) => {
      return groupItems.filter(item => isQuestionVisible(item))
    }

    onMounted(() => {
      const firstGroup: any = document.querySelector('.horaki-section_title:not(.review-header-main)')
      if (firstGroup) firstGroup.style.borderTop = 'none'
    })

    const isStr = (item: any) => {
      return typeof item === 'string'
    }

    const sectionHasAnswers = (lt: Section) => {
      const q1 = lt.items.filter(t => isStr(t)).length
      const q2 = lt.items.filter(item => typeof item === 'object' && item.items.filter(t => isStr(t)).length).length
      return q1 + q2
    }

    const isCurrentNodeHovered = (depth: number, index: number) => {
      return isHoveredIndex.value === `d${depth}q${index}`
    }
    return {
      markDown,
      groupSum,
      isHoveredIndex,
      isQuestionVisible,
      isThisGroupVisible,
      filteredGroupItems,
      sectionHasAnswers,
      isCurrentNodeHovered,
      groupRequiresUpdate
    }
  }
})
