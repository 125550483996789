










import { binderModule } from '@/store/store-accessor'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ReviewLauncherButton',
  props: {
    isDefaultStyle: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const toggleReviewOverlay = () => {
      binderModule.setDrawerState(true)
      binderModule.setReviewOverlay()
    }
    return { toggleReviewOverlay }
  }
})

