




































import { defineComponent, ref, watchEffect } from '@vue/composition-api'
import useIntersection from '@/use/useIntersection'
import LoadingIcon from '@/components/loading-icon/index.vue'

export default defineComponent({
  name: 'ReviewActions',
  components: {
    LoadingIcon
  },
  props: {
    btnLoading: {
      type: Boolean,
      default: false
    },
    isOverlay: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const options = {
      root: null,
      rootMargin: '0px 0px -200px',
      threshold: 1
    }

    const elRef = ref(null)
    const elClass = ref('')
    const { observedEntry } = useIntersection(elRef, options)

    watchEffect(() => {
      if (!observedEntry.value) return
      const isVisible = observedEntry.value.intersectionRatio === 1
      elClass.value = isVisible ? 'is-visible' : ''
    })
    return {
      elRef,
      elClass
    }
  }

})
