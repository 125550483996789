













import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ProgressBar'
})
export default class ProgressBar extends Vue {
  show = false
  progress = 0
  opacity = 0

  get progressBarStyle () {
    return {
      width: `${this.progress}%`,
      opacity: this.opacity,
      transition: 'all 250ms linear'
    }
  }

  beforeEnter () {
    this.opacity = 0
    this.progress = 0
  }

  enter (el: Element, done: () => void) {
    this.opacity = 1
    done()
  }

  mounted () {
    this.start()
  }

  start () {
    this.incrementProgress()
    this.opacity = 1
    this.show = true
  }

  finish () {
    this.setProgress(100)
    setTimeout(() => {
      this.show = false
      this.opacity = 0
      this.setProgress(0)
    }, 100)
  }

  incrementProgress () {
    setTimeout(() => {
      this.setProgress(this.progress + 0.5)
      if (this.progress < 95) {
        this.incrementProgress()
      }
    }, 250)
  }

  setProgress (percentage: number) {
    this.progress = percentage
  }
}
