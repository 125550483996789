

































import { defineComponent, computed } from '@vue/composition-api'
import { InfoIcon, ChevronDownIcon } from 'vue-feather-icons'
import CalloutBox from '@/components/callout-box/index.vue'
import { binderModule } from '@/store/store-accessor'
import VueScrollTo from 'vue-scrollto'

export default defineComponent({
  name: 'RequireUpdateAlert',
  components: { InfoIcon, CalloutBox, ChevronDownIcon },
  props: {
    isOverlay: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const validationMessages = computed(() => {
      return binderModule.validationMessages
    })
    const jumpToQuestion = () => {
      const el = document.querySelector(`[data-qid=${binderModule.validationMessages[0].field}]`)
      const container = props.isOverlay ? document.querySelector('.overlay-content') : document.querySelector('body')
      if (el) {
        VueScrollTo.scrollTo(el, 200, {
          container: (container as HTMLElement),
          offset: -330
        })
      }
    }

    return {
      jumpToQuestion,
      validationMessages

    }
  }
})
