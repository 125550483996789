




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { InfoIcon } from 'vue-feather-icons'
import EventBus from '@/event-bus'
import { Events } from '@/models/events'

@Component({
  name: 'QuoteItem',
  components: {
    InfoIcon
  }
})
export default class QuoteItem extends Vue {
  @Prop({ default: () => [] }) hint !: string[]

  get displayHint () {
    return this.hint.length > 0
  }

  toggleHint () {
    EventBus.$emit(Events.TOGGLE_HINT, this.hint)
  }
}
