














































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Drawer from '@/components/drawer/index.vue'
import { ChevronLeftIcon, XIcon } from 'vue-feather-icons'
import PolicyCart from '@/components/quote/policy-cart/index.vue'
import { CartItem } from '@/models/cart'
import LoadingIcon from '@/components/loading-icon/index.vue'
import { ROUTES } from '@/router/routes'
import { binderModule, quoteModule } from '@/store/store-accessor'
import { Quote } from '@/models/quote'
import EventBus from '@/event-bus'

@Component({
  name: 'DrawerPolicyCart',
  components: { LoadingIcon, PolicyCart, Drawer, XIcon, ChevronLeftIcon }
})
export default class DrawerPolicyCart extends Vue {
  @Prop({ required: true }) cartItems!: CartItem[];
  @Prop({ required: true, default: false }) isOpen!: boolean;
  @Prop({ required: true }) quotePendingUpdate!: number | null;
  @Prop({ default: false }) isCheckout!: boolean;
  quoteRequiresUpdate = false;
  loading = false;
  ctaStates = {
    UPDATE: 'Update policy',
    CHECKOUT: 'Proceed to checkout'
  };

  mounted () {
    EventBus.$on('quote-state-requires-update', (val: boolean) => {
      this.quoteRequiresUpdate = val
    })
  }

  backToCheckout () {
    if (this.isCheckout) this.$emit('toggle-cart')
    this.$emit('set-quote-pending-update', null)
  }

  proceedToCheckout () {
    this.loading = true
    if (this.quotePendingUpdate) {
      return this.updateQuote()
    } else {
      return this.goToAcknowledgements()
    }
  }

  async updateQuote () {
    const quoteId = quoteModule.quoteSelections[0]?.quoteId
    await quoteModule.updateQuote({
      id: this.$route.params.id,
      quote: quoteModule.quotesData.find(
        (quote) => quote.id === quoteId
      ) as Quote
    })
  }

  async goToAcknowledgements () {
    const quoteId = quoteModule.quoteSelections[0]?.quoteId
    // check if there are acknowledgement questions before proceeding to route
    // if not, proceed to checkout route
    await quoteModule.fetchAcknowledgements({ quoteId })
    binderModule.setShowCart()

    // Check of any of the questions returned is conditionally visible
    // If not visible, then redirect to checkout, otherwise continue to acknowledgments
    if (quoteModule.acknowledgmentsQuestions.length > 0) {
      await this.$router.push({
        name: ROUTES.ACKNOWLEDGMENTS,
        params: { id: this.$route.params.id, quoteid: quoteId.toString() }
      })
    } else {
      await this.$router.push({
        name: ROUTES.CHECKOUT,
        params: { id: this.$route.params.id }
      })
    }
  }

  toggleCart () {
    this.$emit('toggle-cart')
  }
}
