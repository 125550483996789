




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ctaState } from '@/models/quote'
import EventBus from '@/event-bus'

@Component({
  name: 'QuoteCta'
})
export default class QuoteCta extends Vue {
  @Prop({ default: 'select' }) state !: string

  @Watch('state')
  onStateChanged (val: string) {
    EventBus.$emit('quote-state-requires-update', val === ctaState.UPDATE_POLICY)
  }

  get isAddToCart () {
    return this.state === ctaState.ADD_TO_CART
  }

  get isInCart () {
    return this.state === ctaState.IN_CART
  }

  get isEditing () {
    return this.state === ctaState.EDIT_REMOVE_FROM_CART
  }

  get requiresUpdating () {
    return this.state === ctaState.UPDATE_POLICY
  }
}
