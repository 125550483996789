
















import { defineComponent, computed } from '@vue/composition-api'
import Review from '@/views/Review.vue'
import { binderModule } from '@/store/store-accessor'

export default defineComponent({
  name: 'ReviewOverlay',
  components: { Review },
  emits: ['answers-changed'],
  setup () {
    const showReviewOverlay = computed(() => {
      return binderModule.toggleReviewOverlay
    })

    return { showReviewOverlay }
  }

})
